import Image from 'next/image';
import { memo } from 'react';

import { bustMediaCache, Video } from '@swordhealth/ui-corporate';

import styles from './styles.module.css';

function Media({ alternativeText = '', mime, objectFit, url, updatedAt, ...props }) {
  const src = bustMediaCache(url, updatedAt);

  if (!src || !mime) {
    return null;
  }

  if (mime.includes('video')) {
    return (
      <Video
        {...props}
        url={src}
        mime={mime}
        objectFit={objectFit || 'cover'}
        data-testid="media-video"
      />
    );
  }

  if (mime.includes('svg')) {
    return <img {...props} src={src} alt={alternativeText || ''} data-testid="media-svg" />;
  }

  if (mime.includes('image')) {
    return (
      <Image
        {...props}
        {...(objectFit && { className: styles[objectFit] })}
        src={src}
        alt={alternativeText || ''}
        fill={!props.width}
        data-testid="media-image"
      />
    );
  }

  return null;
}

export default memo(Media);
