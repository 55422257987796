import { Paragraph } from '@swordhealth/ui-corporate';
import { Container } from '@/components/core';

import styles from './styles.module.css';

const Disclaimer = ({ content }) => {
  return (
    <section className={styles.wrapper}>
      <Container>
        <div className={styles.content}>
          <Paragraph size="xs" weight="medium" className={styles.disclaimer}>
            {content}
          </Paragraph>
        </div>
      </Container>
    </section>
  );
};

export default Disclaimer;
